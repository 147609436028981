import {timeFormat, numberFormat} from "@custom/index";
// 租金回笼tabs
export const rentWithdrawalTabOptions = [
    {
        label: "租金回笼",
        name: "rentWithdrawal"
    },
    {
        label: "水电费",
        name: "waterElectricity"
    },
    {
        label: "押金",
        name: "deposit"
    },
    {
        label: "定金",
        name: "dingJIn"
    },
    {
        label: "出租率",
        name: "rentalRate"
    },
    {
        label: "租金统计",
        name: "rentStatistics"
    },
    /*{
        label: "账单详情",
        name: "billDetails"
    },
    {
        label: "账单管理",
        name: "billManagement"
    }*/
];

//租金回笼 租金列表
export function rentTableColumn(that){
    return [
        {
            prop: "start_date",
            label: "月份",
        },
        {
            prop: "code_name",
            label: "住房类型"
        },
        {
            prop: "receivable",
            label: "应收租金(元)",
            render: (h, {row}) => {
                return h("span", numberFormat(row.receivable / 100));
            },
            sortable: true
        },
        {
            prop: "official",
            label: "实收租金(元)",
            render: (h, {row: {official, start_date: month, code_name:houseType}}) => {
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {click() {that.$router.push({name: "payment-record", params: {data: {month, houseType, amountType: 0}}});}}
                }, numberFormat(official / 100));
            },
            sortable: true
        },
        {
            prop: "receivablePercent",
            label: "应收租金完成率",
            render: (h, {row}) => {
                return h("span", row.receivablePercent + "%");
            },
            sortable: true
        },
        {
            prop: "debt",
            label: "应收租金欠款",
            render: (h, {row}) => {
                return h("span", numberFormat(row.debt / 100));
            },
            sortable: true
        },
        {
            prop: "officialDebt",
            label: "收回租金欠款",
            render: (h, {row: {officialDebt, start_date: month, code_name:houseType}}) => {
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {click() {that.$router.push({name: "payment-record", params: {data: {month, houseType, amountType: 1}}});}}
                }, numberFormat(officialDebt / 100));
            }
        },
        {
            prop: "debtPercent",
            label: "欠款完成率",
            render: (h, {row}) => {
                return h("span", row.debtPercent + "%");
            }
        },
        {
            prop: "officialSum",
            label: "实收合计",
            render: (h, {row}) => {
                return h("span", numberFormat(row.officialSum / 100));
            },
        },
        {
            prop: "advance",
            label: "预收金额",
            render: (h, {row: {advance, start_date: month, code_name:houseType}}) => {
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {click() {that.$router.push({name: "payment-record", params: {data: {month, houseType, amountType: 2}}});}}
                }, numberFormat(advance / 100));
            },
        },
        {
            prop: "moneySum",
            label: "租金合计",
            render: (h, {row}) => {
                return h("span", numberFormat(row.moneySum / 100));
            },
        },
    ];
}

//折线eCharts
export const eChartsOptions = {
    tooltip: {
        trigger: 'axis',
        formatter: function (params) {
            let rtHtml = `<div><div>${params[0].axisValue}</div>`;
            for (let item of params) {
                rtHtml += `<div style="display:flex;justify-content: space-between">
                            <div>${item.marker}${item.seriesName}<span style="padding: 0 5px"></span></div>
                            <div>${numberFormat(item.value)}</div>
                         </div>`;
            }
            rtHtml += `</div>`;
            return rtHtml;
        }
    },
    grid: {
        left: '0%',
        right: '3%',
        bottom: '0%',
        containLabel: true
    },
    toolbox: {
        feature: {
            saveAsImage: {}
        }
    },
    xAxis: {
        type: 'category',
        boundaryGap: false,
        data: []
    },
    yAxis: {
        type: 'value'
    },
    series: []
};

//水电费 水电费列表
export const waterElectrTableColumn = [
    {
        prop: "start_date",
        label: "月份",
        //width: 100
    },
    {
        prop: "code_name",
        label: "住房类型"
    },
    {
        prop: "type",
        label: "收费项目"
    },
    {
        prop: "receivable",
        label: "应收",
        render: (h, {row}) => {
            return h("span", numberFormat(row.receivable / 100));
        },
    },
    {
        prop: "official",
        label: "实收",
        render: (h, {row}) => {
            return h("span", numberFormat(row.official / 100));
        },
    },
    {
        prop: "uncollected",
        label: "未收",
        render: (h, {row}) => {
            return h("span", numberFormat(row.uncollected / 100));
        },
    },
    {
        prop: "refund",
        label: "退费",
        render: (h, {row}) => {
            return h("span", numberFormat(row.refund / 100));
        },
    },
    {
        prop: "totalSum",
        label: "总合计",
        render: (h, {row}) => {
            return h("span", numberFormat(row.totalSum / 100));
        },
    }
];

//押金 押金列表
export const depositTableColumn = [
    {
        prop: "month",
        label: "月份",
        //width: 100
    },
    {
        prop: "codeName",
        label: "住房类型"
    },
    {
        prop: "zfbPercent",
        label: "支付宝",
        render: (h, {row}) => {
            return h("span", row.zfbPercent + "%");
        },
    },
    {
        prop: "wxPercent",
        label: "微信",
        render: (h, {row}) => {
            return h("span", row.wxPercent + "%");
        },
    },
    {
        prop: "xjPercent",
        label: "现金",
        render: (h, {row}) => {
            return h("span", row.xjPercent + "%");
        },
    },
    {
        prop: "zzPercent",
        label: "转账",
        render: (h, {row}) => {
            return h("span", row.zzPercent + "%");
        },
    },
    {
        prop: "skPercent",
        label: "刷卡",
        render: (h, {row}) => {
            return h("span", row.skPercent + "%");
        },
    },
    {
        prop: "deposit",
        label: "押金合计",
        render: (h, {row}) => {
            return h("span", numberFormat(row.deposit / 100));
        },
    },
    {
        prop: "forRent",
        label: "抵房租",
        render: (h, {row}) => {
            return h("span", numberFormat(row.forRent / 100));
        },
    },
    {
        prop: "forService",
        label: "抵服务费",
        render: (h, {row}) => {
            return h("span", numberFormat(row.forService / 100));
        },
    },
    {
        prop: "forElectWater",
        label: "抵水电费",
        render: (h, {row}) => {
            return h("span", numberFormat(row.forElectWater / 100));
        },
    },
    {
        prop: "forElse",
        label: "抵其他费用",
        render: (h, {row}) => {
            return h("span", numberFormat(row.forElse / 100));
        },
    },
];

//押金 保证金支付占比eCharts
export const paymentEChartsOptions = {
    tooltip: {
        trigger: 'item',
        formatter: function (params) {
            return `${params.marker}${params.data.name}<span style="padding: 0 5px"></span> ${numberFormat(params.value / 100)}元`;
        }
    },
    color: ['#5087EC', '#68BBC4', '#58A55C', '#F2BD42', '#EE752F'],
    series: [
        {
            name: 'Access From',
            type: 'pie',
            radius: ['45%', '80%'],
            itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
            },
            labelLine: {},
            data: [
                {value: 0, name: '微信'},
                {value: 0, name: '支付宝'},
                {value: 0, name: '转账'},
                {value: 0, name: '现金'},
                {value: 0, name: '刷卡'}
            ],
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }
    ]
};

//定金 定金列表
export const dingJInTableColumn = [
    {
        prop: "index",
        label: "月份",
    },
    {
        prop: "",
        label: "住房类型"
    },
    {
        prop: "",
        label: "支付宝罚没金额"
    },
    {
        prop: "",
        label: "微信罚没金额"
    },
    {
        prop: "",
        label: "现金罚没金额"
    },
    {
        prop: "",
        label: "转账罚没金额"
    },
    {
        prop: "",
        label: "刷卡罚没金额"
    },
    {
        prop: "",
        label: "支付宝转租金"
    },
    {
        prop: "",
        label: "微信转租金"
    },
    {
        prop: "",
        label: "现金转租金"
    },
    {
        prop: "",
        label: "转账转租金"
    },
    {
        prop: "",
        label: "刷卡转租金"
    }
];

//定金 罚没定金支付占比
export const famoDingJinEChartsOptions = {
    tooltip: {
        trigger: 'item',
        formatter: function (params) {
            return `${params.marker}${params.data.name}<span style="padding: 0 5px"></span> ${numberFormat(params.value / 100)}元`;
        }
    },
    color: ['#5087EC', '#68BBC4', '#58A55C', '#F2BD42', '#EE752F'],
    series: [
        {
            name: 'Access From',
            type: 'pie',
            radius: ['55%', '90%'],
            itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
            },
            labelLine: {},
            data: [
                {value: 0, name: '微信'},
                {value: 0, name: '支付宝'},
                {value: 0, name: '转账'},
                {value: 0, name: '现金'},
                {value: 0, name: '刷卡'}
            ],
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }
    ]
};

//定金 定金转租金支付占比
export const dingJinZhuanRentEChartsOptions = {
    tooltip: {
        trigger: 'item',
        formatter: function (params) {
            return `${params.marker}${params.data.name}<span style="padding: 0 5px"></span> ${numberFormat(params.value / 100)}元`;
        }
    },
    color: ['#5087EC', '#68BBC4', '#58A55C', '#F2BD42', '#EE752F'],
    series: [
        {
            name: 'Access From',
            type: 'pie',
            radius: ['55%', '90%'],
            itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
            },
            labelLine: {},
            data: [
                {value: 0, name: '微信'},
                {value: 0, name: '支付宝'},
                {value: 0, name: '转账'},
                {value: 0, name: '现金'},
                {value: 0, name: '刷卡'}
            ],
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }
    ]
};

//出租率 出租率列表
export const rentalRateTableColumn = [
    {
        prop: "month",
        label: "月份",
    },
    {
        prop: "apartmentCodeName",
        label: "住房类型"
    },
    {
        prop: "totalCount",
        label: "总房间数"
    },
    {
        prop: "rentOutCount",
        label: "出租房间数"
    },
    {
        prop: "rentOutPercent",
        label: "房间出租率"
    },
    {
        prop: "totalArea",
        label: "总建筑面积"
    },
    {
        prop: "rentOutArea",
        label: "出租面积"
    },
    {
        prop: "areaPercent",
        label: "面积出租率"
    }
];

//租金统计 租金统计列表
export const rentStatisticsRateTableColumn = [
    {
        prop: "month",
        label: "月份",
    },
    {
        prop: "apartmentTypeCodeName",
        label: "住房类型"
    },
    {
        prop: "payTypeName",
        label: "收费项目"
    },
    {
        prop: "AliPay",
        label: "支付宝",
        render: (h, {row}) => {
            return h("span", numberFormat(row.AliPay / 100));
        },
    },
    {
        prop: "WeChat",
        label: "微信",
        render: (h, {row}) => {
            return h("span", numberFormat(row.WeChat / 100));
        },
    },
    {
        prop: "cash",
        label: "现金",
        render: (h, {row}) => {
            return h("span", numberFormat(row.cash / 100));
        },
    },
    {
        prop: "transfer",
        label: "转账",
        render: (h, {row}) => {
            return h("span", numberFormat(row.transfer / 100));
        },
    },
    {
        prop: "flash",
        label: "刷卡",
        render: (h, {row}) => {
            return h("span", numberFormat(row.flash / 100));
        },
    },
    /*{
        prop: "",
        label: "水电公摊"
    },*/
    {
        prop: "returnAnmount",
        label: "退租金",
        render: (h, {row}) => {
            return h("span", numberFormat(row.returnAnmount / 100));
        },
    },
    {
        prop: "sumAmount",
        label: "合计",
        render: (h, {row}) => {
            return h("span", numberFormat(row.sumAmount / 100));
        },
    }
];

//账单管理 列表
export const billTableColumn = [
    {
        type: 'selection',
        width: 70,
        selectable:(row) => {
            // console.log(row)
            return !row.tuiFang;
        },
    },
    {
        prop: "billNum",
        label: "账单号",
        width: 180,
    },
    {
        prop: "apartment.community.district",
        label: "区域",
    },
    {
        prop: "apartmentTypeName",
        label: "住房类型",
        width: 80
    },
    {
        prop: "contract.apartment.currentBoundLeasor.type",
        label: "租户类型",
        width: 80
    },
    {
        prop: "typeName",
        label: "收费类型",
        width: 100
    },
    /*{
        prop: "contract.apartment.community.name",
        label: "小区名称",
        showOverflowTooltip: true
    },
    {
        prop: "contract.apartment.community.district",
        label: "所属区域",
        showOverflowTooltip: true
    },*/
    {
        prop: "contract.apartment.name",
        label: "座落",
    },
    {
        prop: "leasorName",
        label: "租户姓名",
        width: 80
    },
    {
        prop: "apartment.currentBoundLeasor.tel1",
        label: "租户电话",
        width: 120
    },
    {
        prop: "stateCodeName",
        label: "缴费状态",
        width: 80
    },
    {
        label: "支付渠道",
        width: 80,
        render: (h, {row}) => {
            const {paymentChannel} = row;
            return h("span", paymentChannel ? paymentChannel : "未支付");
        },
    },
    {
        label: "缴费金额(元)",
        prop: "amount",
        width: 100,
        render: (h, {row}) => {
            const {stateCode} = row;
            return h("span",{
                style: {
                    textDecoration: stateCode === 600604 ? 'line-through' : ''
                }
            }, numberFormat(row.amount / 100));
        },
    },
    {
        label: "收款时间",
        width: 150,
        render: (h, {row}) => {
            const {paidDate} = row;
            let date = paidDate ? timeFormat(new Date(row.paidDate), "yyyy-MM-dd HH:mm:ss") : "未缴费";
            return h("span", date);
        },
    },
    {
        label: "账单开始时间",
        width: 100,
        render: (h, {row}) => {
            const {startDate} = row;
            let date = startDate ? timeFormat(new Date(row.startDate)) : "无";
            return h("span", date);
        },
    },
    {
        label: "账单结束时间",
        width: 100,
        render: (h, {row}) => {
            const {endDate} = row;
            let date = endDate ? timeFormat(new Date(row.endDate)) : "无";
            return h("span", date);
        },
    },
    {
        label: "票据",
        width: 100,
        render: (h, {row}) => {
            const {billingStatus} = row;
            if(billingStatus === 0) return '待发起'
            if(billingStatus === 1) return '待审核'
            if(billingStatus === 2) return '发票审核不通过'
            if(billingStatus === 3) return '发票开具成功'
            if(billingStatus === 4) return '发票开具失败'
            if(billingStatus === 5) return '收据开具成功'
        },
    },
    // {
    //     label: "票据",
    //     width: 100,
    //     render: (h, {row}) => {
    //         // const {endDate} = row;
    //         // let date = endDate ? timeFormat(new Date(row.endDate)) : "无";
    //         return h("span", row.leasorName);
    //     },
    // },
];
